import React from "react";

const LogoSvg = ({ className }) => {
    return (
        <svg
            id="logoSvg"
            data-name="Layer 2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 572.63 275.39"
            className={className}
        >
            <defs>
                <style>
                    {`\n      
                        #logoSvg .cls-1 {\n        
                            fill: #663b49;\n      
                            transition: all var(--transition-smooth) ease;
                        }\n\n      
                        .darkmode #logoSvg .cls-1 {\n        
                            fill: #fff;\n      
                            
                        }\n\n      
                        #logoSvg .cls-2 {\n        
                            opacity: .3;\n      
                        }\n    
                        `}
                </style>
            </defs>
            <g id="Layer_1-2" data-name="Layer 1">
                <g>
                    <g>
                        <g>
                            <path
                                className="cls-1"
                                d="m130.23,0C77.17,0,37.26,36.69,37.26,90.48v1.98c0,24.58,7.03,45.81,20.56,61.52l2.96-32.62,41.52,58.53c2.62.62,5.31,1.14,8.07,1.57l7.66-5.45,21.05-32.1c-2.3.24-4.76.36-7.38.36-30.74,0-41.39-22.31-41.39-51.07v-1.98c0-31.73,15.37-51.31,40.16-51.31,18.84,0,29.74,8.68,31.98,25.29h50.32C209.55,19.09,172.12,0,130.23,0Zm3.47,82.31v34.7h33.47c-.64,6.13-2.89,11.54-6.78,16.55l25.82,23.88,3.51,7.51c18.09-14.31,27.28-36.03,27.28-61.08v-21.56h-83.29Z"
                            />
                            <path
                                className="cls-1"
                                d="m323.82,136.1c-1.24,7.18-6.69,13.88-20.33,13.88s-23.05-8.43-24.29-24.05h89.98v-11.9c0-46.6-30.99-67.42-67.43-67.42-39.66,0-71.89,26.02-71.89,68.17v1.99c0,42.88,30.74,66.43,71.89,66.43s63.71-17.35,67.67-47.1h-45.61Zm-22.06-58.01c13.13,0,20.08,8.43,20.32,22.31h-42.64c1.98-14.63,10.41-22.31,22.31-22.31Z"
                            />
                            <path
                                className="cls-1"
                                d="m452.22,46.61c-40.9,0-72.63,26.02-72.63,68.17v1.99c0,14.75,3.83,27.25,10.51,37.28l22.15-20.49,9.03-8.35,12.26,18.71,21.05,32.1,7.65,5.45,1.41,1.01c1.84-.23,3.66-.52,5.44-.86l1.22-1.72,41.52-58.53,2.76,30.38c6.07-9.97,9.51-22.14,9.51-36.23v-1.98c0-41.65-31.48-66.93-71.89-66.93Zm22.31,69.41c0,19.83-7.44,31.23-22.31,31.23-15.62,0-22.8-11.15-22.8-31.97v-1.98c0-20.08,7.93-30.49,22.8-30.49s22.31,11.16,22.31,31.23v1.98Z"
                            />
                        </g>
                        <path
                            className="cls-1"
                            d="m0,212.7l40.25-21.32-1.83,1.63,13.74-26.66-.62,1.99c.88-8.35,5.5-51.36,6.28-58.73,0,0,6.39,9.32,6.39,9.32l43.95,64.12-4.11-.69,12.42-8.55-.69.72,33-51.01,2.54-3.92,2.13,3.35c1.89,2.98,5.86,5.98,8.37,8.42,8.1,7.48,18.23,16.58,26.5,23.78,0,0,.44.38.44.38l.27.59,8.73,19.11-1.67-1.52,35.24,15.65-37.21-10.14-1.08-.3-.59-1.22-9.08-18.95.71.97c-4.79-4.85-12.12-11.65-17.1-16.46,0,0-8.66-8.11-8.66-8.11-2.65-2.34-5.91-6.02-9.05-7.69,0,0,4.66-.57,4.66-.57l-33.62,50.6-.27.41c-1.99,1.47-13.11,9.66-14.89,10.98,0,0-1.77-2.42-1.77-2.42l-46-62.67,7.6-2.05-3.53,47.5-.08,1.12-.54.87-15.73,25.53-.8,1.29-1.03.34L0,212.7H0Z"
                        />
                        <g className="cls-2">
                            <polygon
                                className="cls-1"
                                points="73.22 154.52 72.3 164.65 79.21 170.64 68.92 180.77 53.88 186.6 65.24 171.87 65.97 142.36 73.22 154.52"
                            />
                        </g>
                        <g className="cls-2">
                            <polygon
                                className="cls-1"
                                points="122.49 181.08 151.04 139.17 143.06 162.89 136.77 169.72 140.3 179.24 122.49 181.08"
                            />
                        </g>
                        <g className="cls-2">
                            <polygon
                                className="cls-1"
                                points="156.57 159.51 153.81 137.64 179.36 160.12 192.03 185.76 178.79 177.12 177.06 185.76 173.84 178.85 165.2 193.47 169.46 169.41 159.91 164 161.75 158.59 156.57 159.51"
                            />
                        </g>
                        <path
                            className="cls-1"
                            d="m0,212.7l40.26-21.32-1.83,1.63,13.73-26.66-.62,1.99c.88-8.35,5.5-51.35,6.28-58.72,0,0,6.39,9.32,6.39,9.32l43.95,64.12-4.11-.7,12.42-8.55-.69.72,33-51.01,2.54-3.92,2.13,3.35c1.89,2.98,5.86,5.98,8.37,8.42,8.1,7.48,18.23,16.57,26.5,23.78,0,0,.44.38.44.38l.27.59,8.73,19.11-1.67-1.52,35.24,15.65-37.2-10.13-1.09-.3-.58-1.22-9.08-18.95.71.97c-4.79-4.85-12.12-11.65-17.1-16.46,0,0-8.66-8.11-8.66-8.11-2.66-2.33-5.91-6.02-9.05-7.69,0,0,4.66-.57,4.66-.57l-33.62,50.6-.27.41c-1.99,1.47-13.11,9.66-14.89,10.98,0,0-1.77-2.42-1.77-2.42l-45.99-62.67,7.6-2.05-3.54,47.49-.08,1.12-.54.87-15.73,25.53-.8,1.29-1.03.34L0,212.7h0Z"
                        />
                        <g className="cls-2">
                            <polygon
                                className="cls-1"
                                points="499.41 154.52 500.33 164.65 493.43 170.64 503.71 180.77 518.75 186.6 507.39 171.87 506.67 142.36 499.41 154.52"
                            />
                        </g>
                        <g className="cls-2">
                            <polygon
                                className="cls-1"
                                points="450.14 181.08 421.59 139.17 429.57 162.89 435.87 169.72 432.34 179.24 450.14 181.08"
                            />
                        </g>
                        <g className="cls-2">
                            <polygon
                                className="cls-1"
                                points="416.06 159.51 418.83 137.64 393.27 160.12 380.61 185.76 393.85 177.12 395.57 185.76 398.8 178.85 407.43 193.47 403.17 169.41 412.73 164 410.88 158.59 416.06 159.51"
                            />
                        </g>
                        <path
                            className="cls-1"
                            d="m341.31,189.37c11.59-5.6,23.32-10.88,35.1-16.03,0,0-1.9,1.73-1.9,1.73,2.27-5.85,5.35-13.9,7.69-19.6,0,0,.27-.65.27-.65l.79-.81c7.97-8.18,17.61-16.48,26.06-24.26,2.8-2.52,10.24-9.2,12.92-11.62l2.9,4.54c9.2,14.54,23.05,36.67,32.32,51.45,0,0-.88-.92-.88-.92,4.04,3,8.06,6.01,12.11,8.99l-4.4.74c11.06-15.15,23.49-31.93,34.04-47.33,3.1-4.5,12.71-18.39,15.7-22.72.55,6.34,1.43,13.78,1.94,20.06.13,1.79.51,4.18.38,5.94-.3,1.73.01,4.21.19,5.96.72,6.85,1.66,16.74,2.49,23.68,0,0-.36-1.16-.36-1.16,4.88,8.34,10.28,17.51,14.97,25.96,0,0-1.52-1.36-1.52-1.36,3.46,1.54,6.85,3.22,10.24,4.91,10.2,5.06,20.32,10.23,30.26,15.82-14.51-4.48-28.85-9.41-43-14.89,0,0-.94-.38-.94-.38l-.58-.98c-2.18-3.66-5.19-9.29-7.31-13.08-2.29-4.18-5.3-9.61-7.51-13.75,0,0-.04-.57-.04-.57l-.86-11.88c-.27-3.96-.63-7.91-.95-11.87-.14-1.75-.28-4.23-.89-5.9-.45-1.71-.5-4.15-.7-5.91-.37-3.95-.69-7.91-1.02-11.86l5.46,1.47c-11.11,15.15-23.33,32.04-33.85,47.46-4.27,6.15-8.52,12.39-12.75,18.57-2.05-1.42-13.31-9.09-15.57-10.65,0,0-.4-.59-.4-.59l-17.17-25.07c-4.97-7.36-12.25-17.8-17.12-25.1,0,0,7,.86,7,.86-11.19,10.54-23.14,22.33-34.81,32.27,0,0,1.06-1.45,1.06-1.45-2.87,5.45-7.1,13.01-10.11,18.47l-.78,1.41-1.12.32c-12.39,3.45-24.82,6.77-37.34,9.75h0Z"
                        />
                    </g>
                    <g>
                        <path
                            className="cls-1"
                            d="m58.84,274.79v-42.73h9.98l11.65,30,11.41-30h9.8v42.73h-7.35v-33.05l-13.09,33.05h-2.39l-13.27-33.05v33.05h-6.75Z"
                        />
                        <path
                            className="cls-1"
                            d="m108.75,259.49v-.48c0-9.62,6.69-16.14,15.54-16.14,7.77,0,14.76,4.6,14.76,15.78v2.09h-22.89c.24,5.92,3.29,9.26,8.61,9.26,4.3,0,6.63-1.73,7.17-4.78h6.99c-1.02,6.51-6.39,10.16-14.34,10.16-9.14,0-15.84-6.04-15.84-15.9Zm23.13-3.59c-.3-5.32-3.11-7.77-7.59-7.77s-7.29,2.99-8.01,7.77h15.6Z"
                        />
                        <path
                            className="cls-1"
                            d="m143.41,259.67v-.48c0-9.98,5.98-16.31,14.04-16.31,5.08,0,8.31,2.33,10.04,5.44v-19.24h7.23v45.72h-7.23v-5.26c-1.73,3.11-5.8,5.86-10.34,5.86-7.83,0-13.74-5.62-13.74-15.72Zm24.26-.3v-.48c0-6.93-3.17-10.34-8.37-10.34s-8.49,3.76-8.49,10.46v.48c0,6.75,3.53,10.16,8.13,10.16,5.02,0,8.73-3.35,8.73-10.28Z"
                        />
                        <path
                            className="cls-1"
                            d="m183.34,274.79v-45.72h7.23v19.42c1.49-3.05,5.02-5.62,10.1-5.62,6.22,0,10.7,3.71,10.7,12.31v19.6h-7.23v-19c0-4.72-1.91-6.87-6.22-6.87-4,0-7.35,2.51-7.35,7.47v18.41h-7.23Z"
                        />
                        <path
                            className="cls-1"
                            d="m217.4,266.13c0-7.29,7.11-9.92,16.02-9.92h3.88v-1.61c0-4.12-1.43-6.27-5.68-6.27-3.76,0-5.68,1.79-5.98,4.9h-6.99c.66-7.29,6.39-10.34,13.39-10.34s12.49,2.87,12.49,11.41v20.5h-7.11v-3.82c-1.97,2.69-4.78,4.42-9.56,4.42-5.74,0-10.46-2.75-10.46-9.26Zm19.9-2.27v-3.11h-3.71c-5.5,0-9.02,1.25-9.02,5.08,0,2.63,1.43,4.36,5.02,4.36,4.36,0,7.71-2.33,7.71-6.34Z"
                        />
                        <path
                            className="cls-1"
                            d="m253.98,266.07v-17.15h-4.18v-5.38h4.18v-6.87h7.23v6.87h6.81v5.38h-6.81v16.49c0,2.69,1.25,4.06,3.59,4.06,1.43,0,2.51-.24,3.53-.66v5.74c-1.14.3-2.69.78-4.9.78-6.21,0-9.44-3.35-9.44-9.26Z"
                        />
                        <path
                            className="cls-1"
                            d="m289.66,274.79v-42.73h14.04c9.2,0,15.78,3.76,15.78,12.37v.24c0,3.35-.96,5.98-2.81,7.83-1.85,1.85-4.18,3.05-6.87,3.71l12.19,18.59h-8.01l-11.47-17.51h-5.44v17.51h-7.41Zm7.41-22.89h6.69c5.5,0,8.43-2.27,8.43-7.05v-.24c0-5.02-3.17-6.81-8.43-6.81h-6.69v14.1Z"
                        />
                        <path
                            className="cls-1"
                            d="m325.4,266.13c0-7.29,7.11-9.92,16.02-9.92h3.88v-1.61c0-4.12-1.43-6.27-5.68-6.27-3.76,0-5.68,1.79-5.98,4.9h-6.99c.66-7.29,6.39-10.34,13.39-10.34s12.49,2.87,12.49,11.41v20.5h-7.11v-3.82c-1.97,2.69-4.78,4.42-9.56,4.42-5.74,0-10.46-2.75-10.46-9.26Zm19.9-2.27v-3.11h-3.71c-5.5,0-9.02,1.25-9.02,5.08,0,2.63,1.43,4.36,5.02,4.36,4.36,0,7.71-2.33,7.71-6.34Z"
                        />
                        <path
                            className="cls-1"
                            d="m360.85,274.79v-31.25h7.23v4.78c1.49-2.93,4.9-5.44,9.5-5.44,4.06,0,7.35,1.73,8.84,5.62,2.39-3.88,6.69-5.62,10.58-5.62,5.74,0,10.4,3.53,10.4,12.13v19.78h-7.23v-19.3c0-4.54-2.03-6.57-5.56-6.57s-6.93,2.39-6.93,7.17v18.7h-7.17v-19.3c0-4.54-1.97-6.57-5.56-6.57s-6.87,2.39-6.87,7.17v18.7h-7.23Z"
                        />
                        <path
                            className="cls-1"
                            d="m413.62,259.67v-.48c0-9.98,5.98-16.31,14.04-16.31,5.08,0,8.31,2.33,10.04,5.44v-19.24h7.23v45.72h-7.23v-5.26c-1.73,3.11-5.8,5.86-10.34,5.86-7.83,0-13.74-5.62-13.74-15.72Zm24.26-.3v-.48c0-6.93-3.17-10.34-8.37-10.34s-8.49,3.76-8.49,10.46v.48c0,6.75,3.53,10.16,8.13,10.16,5.02,0,8.73-3.35,8.73-10.28Z"
                        />
                        <path
                            className="cls-1"
                            d="m451.28,266.13c0-7.29,7.11-9.92,16.02-9.92h3.88v-1.61c0-4.12-1.43-6.27-5.68-6.27-3.76,0-5.68,1.79-5.98,4.9h-6.99c.66-7.29,6.39-10.34,13.39-10.34s12.49,2.87,12.49,11.41v20.5h-7.11v-3.82c-1.97,2.69-4.78,4.42-9.56,4.42-5.74,0-10.46-2.75-10.46-9.26Zm19.9-2.27v-3.11h-3.71c-5.5,0-9.02,1.25-9.02,5.08,0,2.63,1.43,4.36,5.02,4.36,4.36,0,7.71-2.33,7.71-6.34Z"
                        />
                        <path
                            className="cls-1"
                            d="m486.72,274.79v-31.25h7.23v4.96c1.49-3.05,5.02-5.62,10.1-5.62,6.22,0,10.7,3.71,10.7,12.31v19.6h-7.23v-19c0-4.72-1.91-6.87-6.22-6.87-4,0-7.35,2.51-7.35,7.47v18.41h-7.23Z"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default LogoSvg;
