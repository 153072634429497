import React from "react";
import profile from "../../assets/profile.png";

const ProfileSvg = ({ ...props }) => {
    return (
        <svg
            id="profile_svg"
            data-name="Layer 2"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 479.44 696.93"
            {...props}
        >
            {/* 956551 */}
            <defs>
                <style>
                    {`\n      
                        #profile_svg .cls-1 {\n        
                            fill: #67724d;\n      
                            transform-origin:center center;
                            transform-box:fill-box;    
                            animation-duration: 5s;
                        }\n\n      
                        #profile_svg .cls-2 {\n        
                            fill: #663b49;\n      
                        }\n\n      
                        .darkmode #profile_svg .cls-2 {\n        
                            fill: #956551;\n      
                        }\n\n      
                        #profile_svg .cls-3 {\n        
                            fill: #af886d;\n      
                        }\n
                        `}
                </style>
            </defs>
            <g id="Layer_1-2" data-name="Layer 1">
                <g>
                    <path
                        className=" smooth cls-1"
                        d="m11.65,197.27h284.67c0,157.11-127.56,284.67-284.67,284.67h0V197.27h0Z"
                    />
                    {/* <path
                        className=" smooth cls-1"
                        d="m11.65,197.27h284.67c0,157.11-127.56,284.67-284.67,284.67h0V197.27h0Z"
                    /> */}
                    <g>
                        <path
                            className=" smooth cls-2 "
                            d="m479.44,217.49h0v479.44H0C0,432.32,214.83,217.49,479.44,217.49Z"
                        />
                        <rect className=" smooth cls-3" y={679.21} width={479.44} height={17.72} />
                    </g>
                </g>
                <image
                    width={3024}
                    height={4032}
                    transform="translate(56.39 114.5) scale(.14)"
                    xlinkHref={profile}
                />
            </g>
        </svg>
    );
};

export default ProfileSvg;
