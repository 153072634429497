import React from "react";

const BottomLeftPattern = () => {
    return (
        <svg
            id="bottom_left_pattern_svg"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 982.18 461.54"
            // className="w-full"
        >
            <defs>
                <style>
                    {`\n      
                        #bottom_left_pattern_svg .cls-1, #bottom_left_pattern_svg .cls-2 {\n        
                            fill: none;\n      
                        }\n\n      
                        #bottom_left_pattern_svg .cls-3 {\n        
                            clip-path: url(#clippath);\n      
                        }\n\n      
                        #bottom_left_pattern_svg .cls-2 {\n        
                            stroke: #663b49;\n        
                            stroke-miterlimit: 10;\n        
                            stroke-width: 1.7px;\n
                            transform-origin:center center;
                            transform-box:fill-box;    
                            animation-name: shake;
                            animation-duration: 5s;
                            animation-iteration-count: infinite;
                            animation-timing-function: ease-in-out;  
                        }\n\n      
                        #bottom_left_pattern_svg .cls-2:nth-of-type(odd) {\n        
                            animation-delay:2.5s;
                        }\n\n      
                        #bottom_left_pattern_svg .cls-4 {\n        
                            isolation: isolate;\n      
                        }\n\n      
                        .cls-5 {\n        
                            mix-blend-mode: multiply;\n        
                            opacity: .3;\n      
                        }\n    `}
                </style>
                <clipPath id="clippath">
                    <rect className="cls-1" width={982.18} height={461.54} />
                </clipPath>
            </defs>
            <g className="cls-4">
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <g className="cls-3">
                            <g className="cls-5">
                                <path
                                    className="cls-2"
                                    d="m65.59,362.11h80.72c0,44.55-36.17,80.72-80.72,80.72h0v-80.72h0Z"
                                />
                                <path
                                    className="cls-2"
                                    d="m166.29,362.11h80.72c0,44.55-36.17,80.72-80.72,80.72h0v-80.72h0Z"
                                />
                                <path
                                    className="cls-2"
                                    d="m267,362.11h80.72c0,44.55-36.17,80.72-80.72,80.72h0v-80.72h0Z"
                                />
                                <path
                                    className="cls-2"
                                    d="m367.7,362.11h80.72c0,44.55-36.17,80.72-80.72,80.72h0v-80.72h0Z"
                                />
                                <path
                                    className="cls-2"
                                    d="m468.4,362.11h80.72c0,44.55-36.17,80.72-80.72,80.72h0v-80.72h0Z"
                                />
                                <path
                                    className="cls-2"
                                    d="m569.11,362.11h80.72c0,44.55-36.17,80.72-80.72,80.72h0v-80.72h0Z"
                                />
                                <path
                                    className="cls-2"
                                    d="m669.81,362.11h80.72c0,44.55-36.17,80.72-80.72,80.72h0v-80.72h0Z"
                                />
                                <path
                                    className="cls-2"
                                    d="m770.52,362.11h80.72c0,44.55-36.17,80.72-80.72,80.72h0v-80.72h0Z"
                                />
                                <path
                                    className="cls-2"
                                    d="m871.22,362.11h80.72c0,44.55-36.17,80.72-80.72,80.72h0v-80.72h0Z"
                                />
                                <path
                                    className="cls-2"
                                    d="m65.59,247.34h80.72c0,44.55-36.17,80.72-80.72,80.72h0v-80.72h0Z"
                                />
                                <path
                                    className="cls-2"
                                    d="m166.29,247.34h80.72c0,44.55-36.17,80.72-80.72,80.72h0v-80.72h0Z"
                                />
                                <path
                                    className="cls-2"
                                    d="m267,247.34h80.72c0,44.55-36.17,80.72-80.72,80.72h0v-80.72h0Z"
                                />
                                <path
                                    className="cls-2"
                                    d="m367.7,247.34h80.72c0,44.55-36.17,80.72-80.72,80.72h0v-80.72h0Z"
                                />
                                <path
                                    className="cls-2"
                                    d="m468.4,247.34h80.72c0,44.55-36.17,80.72-80.72,80.72h0v-80.72h0Z"
                                />
                                <path
                                    className="cls-2"
                                    d="m569.11,247.34h80.72c0,44.55-36.17,80.72-80.72,80.72h0v-80.72h0Z"
                                />
                                <path
                                    className="cls-2"
                                    d="m669.81,247.34h80.72c0,44.55-36.17,80.72-80.72,80.72h0v-80.72h0Z"
                                />
                                <path
                                    className="cls-2"
                                    d="m770.52,247.34h80.72c0,44.55-36.17,80.72-80.72,80.72h0v-80.72h0Z"
                                />
                                <path
                                    className="cls-2"
                                    d="m871.22,247.34h80.72c0,44.55-36.17,80.72-80.72,80.72h0v-80.72h0Z"
                                />
                                <path
                                    className="cls-2"
                                    d="m65.59,132.58h80.72c0,44.55-36.17,80.72-80.72,80.72h0v-80.72h0Z"
                                />
                                <path
                                    className="cls-2"
                                    d="m166.29,132.58h80.72c0,44.55-36.17,80.72-80.72,80.72h0v-80.72h0Z"
                                />
                                <path
                                    className="cls-2"
                                    d="m267,132.58h80.72c0,44.55-36.17,80.72-80.72,80.72h0v-80.72h0Z"
                                />
                                <path
                                    className="cls-2"
                                    d="m367.7,132.58h80.72c0,44.55-36.17,80.72-80.72,80.72h0v-80.72h0Z"
                                />
                                <path
                                    className="cls-2"
                                    d="m468.4,132.58h80.72c0,44.55-36.17,80.72-80.72,80.72h0v-80.72h0Z"
                                />
                                <path
                                    className="cls-2"
                                    d="m569.11,132.58h80.72c0,44.55-36.17,80.72-80.72,80.72h0v-80.72h0Z"
                                />
                                <path
                                    className="cls-2"
                                    d="m669.81,132.58h80.72c0,44.55-36.17,80.72-80.72,80.72h0v-80.72h0Z"
                                />
                                <path
                                    className="cls-2"
                                    d="m770.52,132.58h80.72c0,44.55-36.17,80.72-80.72,80.72h0v-80.72h0Z"
                                />
                                <path
                                    className="cls-2"
                                    d="m871.22,132.58h80.72c0,44.55-36.17,80.72-80.72,80.72h0v-80.72h0Z"
                                />
                                <path
                                    className="cls-2"
                                    d="m65.59,17.82h80.72c0,44.55-36.17,80.72-80.72,80.72h0V17.82h0Z"
                                />
                                <path
                                    className="cls-2"
                                    d="m166.29,17.82h80.72c0,44.55-36.17,80.72-80.72,80.72h0V17.82h0Z"
                                />
                                <path
                                    className="cls-2"
                                    d="m267,17.82h80.72c0,44.55-36.17,80.72-80.72,80.72h0V17.82h0Z"
                                />
                                <path
                                    className="cls-2"
                                    d="m367.7,17.82h80.72c0,44.55-36.17,80.72-80.72,80.72h0V17.82h0Z"
                                />
                                <path
                                    className="cls-2"
                                    d="m468.4,17.82h80.72c0,44.55-36.17,80.72-80.72,80.72h0V17.82h0Z"
                                />
                                <path
                                    className="cls-2"
                                    d="m569.11,17.82h80.72c0,44.55-36.17,80.72-80.72,80.72h0V17.82h0Z"
                                />
                                <path
                                    className="cls-2"
                                    d="m669.81,17.82h80.72c0,44.55-36.17,80.72-80.72,80.72h0V17.82h0Z"
                                />
                                <path
                                    className="cls-2"
                                    d="m770.52,17.82h80.72c0,44.55-36.17,80.72-80.72,80.72h0V17.82h0Z"
                                />
                                <path
                                    className="cls-2"
                                    d="m871.22,17.82h80.72c0,44.55-36.17,80.72-80.72,80.72h0V17.82h0Z"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default BottomLeftPattern;
