import React, { useContext, useState } from "react";
import Electric from "../../components/svgs/Electric";

import AuthContext from "../../context/AuthContext";

import "./MainSection.css";

import Button from "../../components/ui/Button";
import MainBg from "../../components/svgs/MainBg";
// import FlexRowReverse from "../../components/ui/FlexRowReverse";
import Container from "../../components/ui/Container";
// import Waleed from "../../components/svgs/Waleed";
// import YearsBooks from "../../components/svgs/YearsBooks";
// import Shapes from "../../components/svgs/Shapes";
// import Waves from "../../components/ui/Waves";
import profile from "../../assets/profile.png";
import BottomLeftPattern from "../../components/svgs/BottomLeftPattern";
import ProfileSvg from "../../components/svgs/ProfileSvg";
import SectionHead from "../../components/ui/SectionHead";
import Categorie from "./CoursesSection/Categorie";
import SubscriptionMethodButton from "./SubscriptionMethodButton";

const MainSection = ({ title = false }) => {
    const { token } = useContext(AuthContext);

    const [isSubscribedCoursesFound, setIsSubscribedCoursesFound] = useState(true);

    const [profileTransform, setProfileTransform] = useState("translate(0,0)");
    const onMouseMove = (e) => {
        let mainSection = document.getElementById("main_section");
        let mainSectionHeight = mainSection.clientHeight;
        let mainSectionWidth = mainSection.clientWidth;

        let ammountToBeMoved = 20;

        let xPercentage = (e.pageX * 2) / mainSectionWidth - 1;
        let yPercentage = (e.pageY * 2) / mainSectionHeight - 1;
        console.log(xPercentage, yPercentage);

        let x = xPercentage * ammountToBeMoved;
        let y = yPercentage * ammountToBeMoved;

        setProfileTransform(`translate(${x}px,${y}px)`);
    };

    if (token) {
        return (
            <section className="relative">
                <SectionHead title={"اشتراكاتك"} />
                <Container>
                    {isSubscribedCoursesFound ? (
                        <>
                            <Categorie
                                api={`/api/sellables/subscribed`}
                                noCoursesPlaceholder="انت غير مشترك بأي كورس حتى الآن!"
                                handleNoCoursesFound={() => setIsSubscribedCoursesFound(false)}
                            />
                            <div className="flex-center-both flex-col space-y-4 font-graph">
                                <div className="font-w-bold">
                                    لو عاوز تعرف تفاصيل اكتر عن حسابك دوس هنا !
                                </div>
                                <Button
                                    element="Link"
                                    to="/me/user"
                                    color="mint"
                                    className="rounded- rounded-tl-[100px] hover:rounded-tl-[0px]  hover:rounded-br-[100px] px-10 py-2"
                                >
                                    ملف المستخدم الخاص بك
                                </Button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="flex-center-both flex-col space-y-4 py-10 font-graph">
                                <div className=" font-w-bold font-h2">
                                    لما تشترك بأي كورس داخل المنصة هيظهرلك هنا !
                                </div>
                                <div className="flex-center-both">
                                    <SubscriptionMethodButton />
                                </div>
                            </div>
                        </>
                    )}
                </Container>
            </section>
        );
    }
    return (
        <>
            <section
                id="main_section"
                className="md:h-screen min-h-screen negative-nav-margin relative overflow-hidden bg-[#d5cfc6] dark:bg-[#663b49] clr-text-primary smooth"
                onMouseMove={onMouseMove}
            >
                <div className="bg-geo-map absolute bottom-0 left-0 w-[75%] md:w-[50%] opacity-50 z-0 dark:brightness-[.1] smooth">
                    {/* <MainBg className="h-full" /> */}
                    <BottomLeftPattern />
                </div>
                <Container className="h-full relative z-10">
                    <div className="w-full flex md:flex-row flex-col-reverse  h-full space-y-10 space-y-reverse md:space-y-0  md:pt-0 pt-20 pb-10 md:pb-0">
                        <div className=" md:basis-1/2 basis-full h-full flex-center-both font-graph">
                            <div className="flex flex-col md:items-start items-center justify-center space-y-5 md:space-y-0">
                                <span className="before-box text-[#663b49] dark:text-lime-800 before:opacity-50 dark:before:opacity-100 before:saturate-[.5] relative">
                                    <h1 className="font-bigmax font-w-bold text-center text-lime-900 saturation-color-with-dark dark:text-slate-50 smooth relative">
                                        م / مدحت{" "}
                                        <span className="relative text-lime-900 dark:text-slate-50 smooth">
                                            رمضان
                                        </span>
                                    </h1>
                                </span>
                                <div className="flex-col text-slate-900 dark:text-slate-300 smooth  pt-5 ">
                                    <h2 className="font-h2">لتدريس كل ما يتعلق بمنهج </h2>
                                    <div className="font-h1 relative">
                                        <span className="relative font-w-bold">
                                            الچيولوچيا و علوم البيئة
                                        </span>
                                    </div>
                                </div>
                                <span className="font-h2 smooth text-lime-900 saturation-color-with-dark dark:text-slate-300 smooth pb-5">
                                    للثانوية العامة
                                </span>
                                {token ? (
                                    ""
                                ) : (
                                    <div>
                                        <Button
                                            element="Link"
                                            to="/register"
                                            color="mint"
                                            className="rounded- rounded-tl-[100px] hover:rounded-tl-[0px]  hover:rounded-br-[100px] px-10 py-2"
                                        >
                                            انشئ حسابك الآن !
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="md:basis-1/2 basis-full h-full flex-center-both">
                            <div className="w-full h-full flex-center-both max-w-sm -mt-20">
                                <ProfileSvg
                                    style={{ transform: profileTransform }}
                                    className="smooth"
                                />
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default MainSection;
