import React, { useContext } from "react";

import profile from "../../assets/Einstein.png";
import Container from "../../components/ui/Container";
import FlexRowReverse from "../../components/ui/FlexRowReverse";

import time from "../../assets/burning-time.svg";
import calendar from "../../assets/calendar.svg";
import exam from "../../assets/contest.svg";
import Testimonials from "./Testimonials";
import LogoSvg from "../../components/svgs/LogoSvg";

import bglight from "../../assets/bg.png";
import bg from "../../assets/bg-light.png";
import ThemeContext from "../../context/ThemeContext";
import AuthContext from "../../context/AuthContext";
import Button from "../../components/ui/Button";
import Waves from "../../components/ui/Waves";
import CenterIcon from "../../components/ui/CenterIcon";
import AboutInfoSection from "./AboutInfoSection";

const AboutSection = () => {
    // const {scrolling}  = useContext

    const { token } = useContext(AuthContext);
    const { darkmode } = useContext(ThemeContext);
    if (token) {
        return <AboutInfoSection />;
    }
    return (
        <>
            <div className="bg-primary-container smooth relative">
                <Waves className="fill-[#d5cfc6] dark:fill-[#663b49] transform scale-y-50 origin-bottom" />
                <Waves className="fill-[#d5cfc6] dark:fill-[#663b49] transform scale-y-50 rotate-180 -translate-y-1/4 absolute w-full h-full inset-0" />
            </div>
            <section className="md:h-screen min-h-screen relative overflow-hidden bg-[#d5cfc6] dark:bg-[#663b49] clr-text-primary smooth">
                <Container className="h-full">
                    <div>
                        <img
                            className="bottom-0 left-0 right-0 w-full z-0 absolute dark:brightness-[.1] smooth"
                            src={darkmode ? bg : bglight}
                            alt="bg-bottom"
                        />
                    </div>
                    <div className="flex-center-both w-full h-full relative space-y-5 flex-col pb-52">
                        <LogoSvg className="max-w-sm" />
                        <span className="text-[#663b49] dark:text-lime-800 before:opacity-50 dark:before:opacity-100 before:saturate-[.5] relative font-graph">
                            <h1 className="font-big font-w-bold text-center text-lime-900 saturation-color-with-dark dark:text-slate-50 smooth relative">
                                م / مدحت{" "}
                                <span className="relative text-lime-900 dark:text-slate-50 smooth">
                                    رمضان
                                </span>
                            </h1>
                        </span>
                        {!token ? (
                            <Button
                                element="Link"
                                to="/register"
                                color="kashmir"
                                className="rounded- rounded-br-[100px] hover:rounded-br-[0px]  hover:rounded-tl-[100px] px-10 py-2"
                            >
                                انضم لينا دلوقتي !
                            </Button>
                        ) : (
                            ""
                        )}
                        {/* <div className="flex-col space-y-30">
                        </div> */}
                    </div>
                </Container>
            </section>
            <AboutInfoSection />
            {/* <Testimonials /> */}
        </>
    );
};

export default AboutSection;
